<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
        <el-form :model="form" :rules="rules" ref="form" :inline="true" :label-position="$config.labelPosition"
                 label-width="120px">
          <div class="form-label-base">
            基本信息
          </div>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请输入" maxlength="25"></el-input>
          </el-form-item>

          <el-form-item label="性别" prop="gender">
            <el-radio-group v-model="form.gender">
            <el-radio label="男"></el-radio>
            <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="类别" prop="category">
            <el-radio-group v-model="form.category">
            <el-radio label="内部驾驶员" disabled></el-radio>
            <el-radio label="外请车主"></el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="挂靠单位" prop="company">
            <el-select v-model="form.company" placeholder="请选择">
            <el-option label="个体" value="个体"></el-option>
            <el-option label="其他" value="其他"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="手机号码" prop="phoneNumber">
            <el-input v-model="form.phoneNumber" placeholder="请输入" maxlength="11"></el-input>
          </el-form-item>

          <el-form-item label="身份证号码" prop="idCard">
            <el-input v-model="form.idCard" placeholder="请输入" maxlength="18"></el-input>
          </el-form-item>

          <el-form-item label="准驾车型" prop="licenseType">
            <el-select v-model="form.licenseType" placeholder="请选择"
                       :disabled="$route.query.type==='detail'">
              <el-option v-for="(item, idx) in $enums.LicenseType.list"
                         :key="idx"
                         :label="item.label"
                         :value="item.value"/>
            </el-select>
          </el-form-item>

          <el-form-item label="初次领证日期" prop="collection">
            <el-date-picker
            v-model="form.collection"
            type="date"
            value-format="timestamp"
            placeholder="选择日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="驾驶证有效期" prop="validity">
            <el-date-picker
            v-model="form.validity"
            type="daterange"
            unlink-panels
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="驾驶证档案编号">
            <el-input v-model="form.certificateEncode" placeholder="请输入" maxlength="64"></el-input>
          </el-form-item>

          <el-form-item label="紧急联系人姓名">
            <el-input v-model="form.emergencyName" placeholder="请输入" maxlength="25"></el-input>
          </el-form-item>

          <el-form-item label="紧急联系人电话">
            <el-input v-model="form.emergencyPhone" placeholder="请输入" maxlength="11"></el-input>
          </el-form-item>

          <el-form-item label="紧急联系人关系">
            <el-input v-model="form.emergencyBetween" placeholder="请输入" maxlength="16"></el-input>
          </el-form-item>

          <el-form-item label="开户行" prop="openingBank">
            <el-input v-model="form.openingBank" placeholder="请输入" maxlength="64"></el-input>
          </el-form-item>

          <el-form-item label="银行卡号" prop="cardNumber">
            <el-input v-model="form.cardNumber" placeholder="请输入" maxlength="32"></el-input>
          </el-form-item>

          <el-form-item label="备注">
            <el-input v-model="form.note" placeholder="请输入" maxlength="64"></el-input>
          </el-form-item>

          <div class="form-label-base">
            证件信息
          </div>
          <el-form-item label="身份证" style="width: 100%">
            <!-- 身份证正面 -->
            <div class="avatar" style="display: inline-block;vertical-align: top;" v-if="form.idCardImgZ.imageUrl ==''">
              <label for="cover_one" class="upload-label">
                <input type="file" hidden @change="OneUploadCover($event)" accept='image/png, image/jpeg'
                       ref="uploadCoverOne"
                       id="cover_one">
                <div class="avatar-item" v-if="valueImage">
                    <img src="../../../assets/images/photo_iocn.png" alt="" class="avatar-icon">
                    <p class="avatar-text">请上传身份证正面</p>
                </div>
              </label>
            </div>
            <!--图片-->
            <div class="order-img avatar" style="display: inline-block;vertical-align: top;" v-else>
              <el-image
                      :src="form.idCardImgZ.imageUrl"
                      v-image-preview
                      fit="cover" style="width: 100%;height: 100%;border-radius: 4px;"></el-image>
              <i class="el-icon-error close-icon" v-if="form.idCardImgZ.isClear" @click="clickClearImg()"></i>
            </div>

            <!-- 身份证反面-->
            <div class="avatar" style="display: inline-block;vertical-align: top; margin-left: 20px" v-if="form.idCardImgF.imageUrl ==''">
              <label for="cover_two" class="upload-label">
                <input type="file" hidden @change="OneUploadCoverF($event)" accept='image/png, image/jpeg'
                       ref="uploadCoverOne"
                       id="cover_two">
                <div class="avatar-item" v-if="valueImageF">
                    <img src="../../../assets/images/photo_iocn.png" alt="" class="avatar-icon">
                    <p class="avatar-text">请上传身份证反面</p>
                </div>
              </label>
            </div>
            <!--图片-->
            <div class="order-img avatar" style="display: inline-block;vertical-align: top; margin-left: 20px" v-else>
              <el-image
                      :src="form.idCardImgF.imageUrl"
                      v-image-preview
                      fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
              <i class="el-icon-error close-icon" v-if="form.idCardImgF.isClear" @click="clickFClearImg()"></i>
            </div>
          </el-form-item>

          <!-- 驾驶证-->
          <el-form-item label="驾驶证" style="width: 100%">
            <div class="avatar" style="display: inline-block;vertical-align: top;" v-if="form.idCardImgJ.imageUrl ==''">
              <label for="cover_three" class="upload-label">
                <input type="file" hidden @change="OneUploadCoverJ($event)" accept='image/png, image/jpeg'
                       ref="uploadCoverOne"
                       id="cover_three">
                <div class="avatar-item-license" v-if="valueImageJ">
                    <img src="../../../assets/images/photo_iocn.png" alt="" class="avatar-icon">
                    <p class="avatar-text">请上传驾驶证正本</p>
                </div>
              </label>
            </div>
            <!--图片-->
            <div class="order-img avatar" style="display: inline-block;vertical-align: top;" v-else>
              <el-image
                      :src="form.idCardImgJ.imageUrl"
                      v-image-preview
                      fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
              <i class="el-icon-error close-icon" v-if="form.idCardImgJ.isClear" @click="clickJClearImg()"></i>
            </div>

            <!-- 驾驶证副业-->
            <div class="avatar" style="display: inline-block;vertical-align: top; margin-left: 20px" v-if="form.idCardImgJF.imageUrl ==''">
              <label for="cover_threeF" class="upload-label">
                <input type="file" hidden @change="OneUploadCoverJF($event)" accept='image/png, image/jpeg'
                       ref="uploadCoverOne"
                       id="cover_threeF">
                <div class="avatar-item-license" style="left:100px" v-if="valueImageJF">
                    <img src="../../../assets/images/photo_iocn.png" alt="" class="avatar-icon">
                    <p class="avatar-text">请上传驾驶证副页</p>
                </div>
              </label>
            </div>
            <!--图片-->
            <div class="order-img avatar" style="display: inline-block;vertical-align: top; margin-left: 20px" v-else>
              <el-image
                      :src="form.idCardImgJF.imageUrl"
                      v-image-preview
                      fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
              <i class="el-icon-error close-icon" v-if="form.idCardImgJF.isClear" @click="clickJFClearImg()"></i>
            </div>
          </el-form-item>

          <!-- 从业资格证-->
          <el-form-item label="从业资格证" style="width: 100%">
            <div class="avatar" style="display: inline-block;vertical-align: top;" v-if="form.idCardImgX.imageUrl ==''">
              <label for="cover_fore" class="upload-label">
                <input type="file" hidden @change="OneUploadCoverX($event)" accept='image/png, image/jpeg'
                       ref="uploadCoverOne"
                       id="cover_fore">
                <div class="avatar-item-license" style="left: 100px" v-if="valueImageX">
                    <img src="../../../assets/images/photo_iocn.png" alt="" class="avatar-icon">
                    <p class="avatar-text">请上传从业资格证</p>
                </div>
              </label>
            </div>
            <!--图片-->
            <div class="order-img avatar" style="display: inline-block;vertical-align: top;" v-else>
              <el-image
                      :src="form.idCardImgX.imageUrl"
                      v-image-preview
                      fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
              <i class="el-icon-error close-icon" v-if="form.idCardImgX.isClear" @click="clickXClearImg()"></i>
            </div>

            <!-- 从业资格证副本-->
            <div class="avatar" style="display: inline-block;vertical-align: top; margin-left: 20px" v-if="form.idCardImgXF.imageUrl ==''">
              <label for="cover_foreF" class="upload-label">
                <input type="file" hidden @change="OneUploadCoverXF($event)" accept='image/png, image/jpeg'
                       ref="uploadCoverOne"
                       id="cover_foreF">
                <div class="avatar-item-license" style="left: 100px" v-if="valueImageXF">
                    <img src="../../../assets/images/photo_iocn.png" alt="" class="avatar-icon">
                    <p class="avatar-text">请上传从业资格证</p>
                </div>
              </label>
            </div>
            <!--图片-->
            <div class="order-img avatar" style="display: inline-block;vertical-align: top; margin-left: 20px" v-else>
              <el-image
                      :src="form.idCardImgXF.imageUrl"
                      v-image-preview
                      fit="cover" style="width: 100%;height: 100%;border-radius: 4px"></el-image>
              <i class="el-icon-error close-icon" v-if="form.idCardImgXF.isClear" @click="clickXFClearImg()"></i>
            </div>
          </el-form-item>

          <el-form-item label="人脸库照片(选填)"  style="width: 100%">
            <div style="margin-bottom:10px; border: 1px dashed #5EACFB;padding: 0 5px;border-radius: 5px;background:rgb(242,248,254)">
              <p><b style="color:#333">提示</b> ：请上传五张人脸库照片，图片像素应大于150*150，图像格式应为JPG或PNG。</p>
              <p><b style="color:#333">拍摄角度要求</b> ：人脸左右旋转角度不超过30度各一张，正脸一张，上下偏转15度左右角度各一张。</p>
              <p><b style="color:#333">拍摄时间要求</b> ：采用近期1年内照片为佳，不超过五年。</p>
            </div>
            <ayl-img-upload
              v-model="faceList"
              :imgListLength="5"
              :imgWidth="'100px'"
              :imgHeight="'100px'"
            ></ayl-img-upload>
          </el-form-item>
        </el-form>
      </div>
      <div class="tac" style="margin-top: 40px">
        <el-button type="primary" @click="submitForm()" :loading="submitLoading">确 认</el-button>
        <el-button style="margin-left: 30px" @click="$router.push('/operation/outside-management')">返 回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      // eslint-disable-next-line no-unused-vars
      const validateAheadAlarmDay =(rule, value, callback) => {
        if(!value) return callback(new Error('请输入提醒天数'))
        if( value>30 || value<1 || !(/^[0-9]*[1-9][0-9]*$/.test(value))){
          callback(new Error('请正确输入1-30之间的整数'))
        }else {
          callback()
        }
      }
      const validateLicenseAwardTime =(rule, value, callback) => {
        if(!value) return callback()
        if(value>this.form.validity[0]){
          callback(new Error('初次领证日期不能大于驾驶证有效开始日期'))
        }else {
          callback()
        }
      }
      const validateContactPhone = (rule, value, callback) => {
        if(!value||!(/^[1][0-9][0-9]{9}$/.test(value))) {
          callback(new Error('请输入11位手机号码'))
        }else {
          callback()
        }
      }
      return {
        nav: [{name: "驾驶员管理", path: ""}, {name: "外请车主维护", path: "/operation/outside-management"},{name: "新增", path: ""}],
        submitLoading: false,
        valueImage: true,
        valueImageF: true,
        valueImageJ: true,
        valueImageJF: true,
        valueImageX: true,
        valueImageXF: true,
        faceList:[],
        form: {
          name:null,                      //姓名
          gender: null,                   //性别
          category: '外请车主',            //类别
          company: null,                  //公司
          phoneNumber: null,              //手机号码
          idCard: null,                   //身份证号
          licenseType: '',              //准驾车型
          collection: null,             //初次领证日期
          validity: [],                 //驾驶证有效期
          certificateEncode: null,      //驾驶证档案编号
          emergencyName: '',            //紧急联系人姓名
          emergencyPhone: '',           //紧急联系人电话
          emergencyBetween: '',         //紧急联系人关系
          openingBank: '',              //开户行
          cardNumber: '',               //银行卡号
          note: '',                     //备注
          // imageUrl: '',
          idCardImgZ: {                 //身份证正面
            imageKey: '',
            imageUrl: '',
            isClear: true,
          },
          idCardImgF: {                 //身份证反面
            imageKey: '',
            imageUrl: '',
            isClear: true,
          },
          idCardImgJ: {                 //驾驶证
            imageKey: '',
            imageUrl: '',
            isClear: true,
          },
          idCardImgJF: {                 //驾驶证副页
            imageKey: '',
            imageUrl: '',
            isClear: true,
          },
          idCardImgX: {                 //从业资格证
            imageKey: '',
            imageUrl: '',
            isClear: true,
          },
          idCardImgXF: {                 //从业资格证副本
            imageKey: '',
            imageUrl: '',
            isClear: true,
          },
        },
        rules: {
          company: {required: true, message: '请选择公司', trigger: 'change'},
          name: [{min: 2,max: 25, required: true, message: '请输入2-25位姓名', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
          gender: {required: true, message: '请选择性别', trigger: 'change'},
          category: {required: true, message: '请选择类别', trigger: 'change'},
          idCard: [{max: 18, required: true, message: '请输入18位身份证号码', trigger: 'blur'},{validator: this.$validator.validateIdNo}],
          phoneNumber: [{required: true, trigger: 'blur',validator: validateContactPhone}],
          licenseType: {required: true, message: '请选择准驾车型', trigger: 'change'},
          collection: [{required: true, message: '请选择领证日期', trigger: 'change'},{validator: validateLicenseAwardTime, trigger: 'blur'}],
          validity: {required: true, message: '请选择驾驶证有效期', trigger: 'change'},
          // emergencyName:[{min: 2,max: 25, required: true, message: '请输入2-25位紧急联系人姓名', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
          // emergencyPhone:[{required: true, trigger: 'blur',validator: validateContactPhone}],
          // emergencyBetween:[{max: 16, required: true, message: '请输入紧急联系人关系', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
          openingBank:[{max: 64, required: true, message: '请输入开户行', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
          cardNumber:[{max: 32, required: true, message: '请输入银行卡号', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
        }
      }
    },
    methods: {
      // 上传身份证正面图片
      async OneUploadCover (e) {
        if (e.target.files.length === 0) return
        if (e.target.files[0].size > 1024 * 1024 * 10) {
          this.$message.warning('上传图片不能超过10M')
          return
        }
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('ailivingToken', JSON.parse(sessionStorage.getItem('loginData')).Token)
        this.form.idCardImgZ.imageKey = await this.$api.UploadImg(formData)
        this.form.idCardImgZ.imageUrl = this.$utils.getObjectURL(file)
        // 不清空input的值，下次选取不到相同的照片
        let oFileInput = document.getElementById('cover_one')
        oFileInput.value = null
      },
      // 上传身份证反面图片
      async OneUploadCoverF (e) {
        console.log(e.target.files)
        if (e.target.files.length === 0) return
        if (e.target.files[0].size > 1024 * 1024 * 10) {
          this.$message.warning('上传图片不能超过10M')
          return
        }
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('ailivingToken', JSON.parse(sessionStorage.getItem('loginData')).Token)
        this.form.idCardImgF.imageKey = await this.$api.UploadImg(formData)
        this.form.idCardImgF.imageUrl = this.$utils.getObjectURL(file)
        // 不清空input的值，下次选取不到相同的照片
        let oFileInput = document.getElementById('cover_two')
        oFileInput.value = null
      },
      // 上传驾驶证图片
      async OneUploadCoverJ (e) {
        if (e.target.files.length === 0) return
        if (e.target.files[0].size > 1024 * 1024 * 10) {
          this.$message.warning('上传图片不能超过10M')
          return
        }
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('ailivingToken', JSON.parse(sessionStorage.getItem('loginData')).Token)
        this.form.idCardImgJ.imageKey = await this.$api.UploadImg(formData)
        this.form.idCardImgJ.imageUrl = this.$utils.getObjectURL(file)
        // 不清空input的值，下次选取不到相同的照片
        let oFileInput = document.getElementById('cover_three')
        oFileInput.value = null
      },
      // 上传驾驶证图片副页
      async OneUploadCoverJF (e) {
        if (e.target.files.length === 0) return
        if (e.target.files[0].size > 1024 * 1024 * 10) {
          this.$message.warning('上传图片不能超过10M')
          return
        }
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('ailivingToken', JSON.parse(sessionStorage.getItem('loginData')).Token)
        this.form.idCardImgJF.imageKey = await this.$api.UploadImg(formData)
        this.form.idCardImgJF.imageUrl = this.$utils.getObjectURL(file)
        // 不清空input的值，下次选取不到相同的照片
        let oFileInput = document.getElementById('cover_threeF')
        oFileInput.value = null
      },
      // 上传行驶证图片
      async OneUploadCoverX (e) {
        if (e.target.files.length === 0) return
        if (e.target.files[0].size > 1024 * 1024 * 10) {
          this.$message.warning('上传图片不能超过10M')
          return
        }
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('ailivingToken', JSON.parse(sessionStorage.getItem('loginData')).Token)
        this.form.idCardImgX.imageKey = await this.$api.UploadImg(formData)
        this.form.idCardImgX.imageUrl = this.$utils.getObjectURL(file)
        // 不清空input的值，下次选取不到相同的照片
        let oFileInput = document.getElementById('cover_fore')
        oFileInput.value = null
      },
      // 上传从业资格证副本
      async OneUploadCoverXF (e) {
        if (e.target.files.length === 0) return
        if (e.target.files[0].size > 1024 * 1024 * 10) {
          this.$message.warning('上传图片不能超过10M')
          return
        }
        let file = e.target.files[0]
        let formData = new FormData()
        formData.append('file', file)
        formData.append('ailivingToken', JSON.parse(sessionStorage.getItem('loginData')).Token)
        this.form.idCardImgXF.imageKey = await this.$api.UploadImg(formData)
        this.form.idCardImgXF.imageUrl = this.$utils.getObjectURL(file)
        // 不清空input的值，下次选取不到相同的照片
        let oFileInput = document.getElementById('cover_foreF')
        oFileInput.value = null
      },
      //  清除身份证正面照片
      clickClearImg () {
        this.form.idCardImgZ.imageKey = ''
        this.form.idCardImgZ.imageUrl = ''
        // this.form.imageInfoVOS.splice(i, 1)
      },
      //  清除身份证正面照片
      clickFClearImg () {
        this.form.idCardImgF.imageKey = ''
        this.form.idCardImgF.imageUrl = ''
      },
      //  清除驾驶证照片
      clickJClearImg () {
        this.form.idCardImgJ.imageKey = ''
        this.form.idCardImgJ.imageUrl = ''
      },
      //  清除驾驶证副页照片
      clickJFClearImg () {
        this.form.idCardImgJF.imageKey = ''
        this.form.idCardImgJF.imageUrl = ''
      },
      //  清除行驶证照片
      clickXClearImg () {
        this.form.idCardImgX.imageKey = ''
        this.form.idCardImgX.imageUrl = ''
      },
      //  清除从业资格证副本
      clickXFClearImg () {
        this.form.idCardImgXF.imageKey = ''
        this.form.idCardImgXF.imageUrl = ''
      },
      async submitInternal(){
        if(this.form.idCardImgZ.imageKey == ''){
          this.$message.error('请上传身份证正面图片');
          return
        }
        if(this.form.idCardImgF.imageKey == ''){
          this.$message.error('请上传身份证反面图片');
          return
        }
        if(this.form.idCardImgJ.imageKey == '' && this.form.idCardImgJF.imageKey == ''){
          this.$message.error('请至少上传一张驾驶证图片');
          return
        }

        if (this.faceList.length > 5) {
          this.faceList.length = 5
        }
        // if(this.form.idCardImgX.imageKey == ''){
        //   this.$message.error('请上传行驶证图片');
        //   return
        // }
        // eslint-disable-next-line no-unused-vars
        let addInternal= await this.$api.driverSave({
          vo:{
            driverName: this.form.name,
            sex: this.form.gender,
            //driverType: this.form.category,
            driverType: '外请车主',
            driverBelong: this.form.company,
            contactPhone: this.form.phoneNumber,
            idCardNumber: this.form.idCard,
            licenseType: this.form.licenseType,
            licenseAwardTime: this.form.collection,
            licenseStartTime: this.form.validity[0],
            licenseEndTime: this.form.validity[1],
            certificateEncode: this.form.certificateEncode,
            emergencyContactName: this.form.emergencyName,
            emergencyContactPhone: this.form.emergencyPhone,
            emergencyContactShipment: this.form.emergencyBetween,
            accBank: this.form.openingBank,
            accNumber: this.form.cardNumber,
            driverRemark: this.form.note,
            idCardImageUrls: [this.form.idCardImgZ.imageKey,this.form.idCardImgF.imageKey],
            drivingLicenseImageUrl: this.form.idCardImgJ.imageKey == '' ? this.form.idCardImgJF.imageKey : this.form.idCardImgJ.imageKey + ',' + this.form.idCardImgJF.imageKey,
            transportLicenseImageUrl: this.form.idCardImgX.imageKey + ',' + this.form.idCardImgXF.imageKey,
            faceDetectPics: this.faceList
          }
        })
        this.$notify({
          title: '成功',
          message: '新增成功',
          type: 'success'
        });
        this.$router.push('/operation/outside-management')
      },
      submitForm() {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.submitInternal()
          } catch (e){}
          this.submitLoading = false
        })
      },
    }
  }
</script>

<style lang='sass' scoped>
.avatar-uploader .el-upload
    border: 1px dashed #d9d9d9
    border-radius: 6px
    cursor: pointer
    position: relative
    overflow: hidden
    vertical-align: top

/deep/ .el-upload
    vertical-align: top

.avatar-uploader .el-upload:hover
    border-color: #409EFF

.avatar-uploader-icon
    font-size: 28px
    color: #8c939d
    width: 300px
    height: 150px
    line-height: 150px
    text-align: center

.avatar
    width: 300px
    height: 150px
    display: block
    border: 1px solid rgba(217,217,217,1)
    border-radius: 4px
    background: rgba(250,250,250,1)
    position: relative
    z-index: 0

.avatar-item
    height: 70px
    position: absolute
    z-index: 1
    top: 38px
    left: 102px

.avatar-item-license
    height: 74px
    position: absolute
    z-index: 1
    top: 38px
    left: 114px

.avatar-icon
    width: 48px
    height: 48px
    display: table-cell
    margin: 0 auto

.avatar-text
    text-align: center
    font-size: 12px
    font-family: Microsoft YaHei
    font-weight: 400
    color: rgba(191,191,191,1)

.avatar-img
    width: 300px
    height: 150px
</style>
